exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-alpha-barbados-cleanup-23-js": () => import("./../../../src/pages/alpha-barbados-cleanup-23.js" /* webpackChunkName: "component---src-pages-alpha-barbados-cleanup-23-js" */),
  "component---src-pages-founders-js": () => import("./../../../src/pages/founders.js" /* webpackChunkName: "component---src-pages-founders-js" */),
  "component---src-pages-gifting-app-js": () => import("./../../../src/pages/gifting-app.js" /* webpackChunkName: "component---src-pages-gifting-app-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-people-index-js": () => import("./../../../src/pages/people/index.js" /* webpackChunkName: "component---src-pages-people-index-js" */),
  "component---src-pages-pledge-js": () => import("./../../../src/pages/pledge.js" /* webpackChunkName: "component---src-pages-pledge-js" */),
  "component---src-pages-pledge-pursuit-js": () => import("./../../../src/pages/pledge-pursuit.js" /* webpackChunkName: "component---src-pages-pledge-pursuit-js" */),
  "component---src-pages-pledge-voluntary-js": () => import("./../../../src/pages/pledge-voluntary.js" /* webpackChunkName: "component---src-pages-pledge-voluntary-js" */),
  "component---src-pages-progress-js": () => import("./../../../src/pages/progress.js" /* webpackChunkName: "component---src-pages-progress-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-publications-index-js": () => import("./../../../src/pages/publications/index.js" /* webpackChunkName: "component---src-pages-publications-index-js" */),
  "component---src-pages-reasons-js": () => import("./../../../src/pages/reasons.js" /* webpackChunkName: "component---src-pages-reasons-js" */),
  "component---src-pages-wishcrafting-js": () => import("./../../../src/pages/wishcrafting.js" /* webpackChunkName: "component---src-pages-wishcrafting-js" */)
}

